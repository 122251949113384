/*!
 * Lightbox for Bootstrap 3 by @ashleydw
 * https://github.com/ashleydw/lightbox
 *
 * License: https://github.com/ashleydw/lightbox/blob/master/LICENSE
 */

.ekko-lightbox-container {
    position: relative;
}

.ekko-lightbox-nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
}

.ekko-lightbox-nav-overlay a {
    z-index: 100;
    display: block;
    width: 49%;
    height: 100%;
    font-size: 30px;
    color: #fff;
    text-shadow: 2px 2px 4px #000;
    opacity: 0;
    filter: dropshadow(color=#000000, offx=2, offy=2);
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.ekko-lightbox-nav-overlay a:empty {
    width: 49%;
}

.ekko-lightbox a:hover {
    text-decoration: none;
    opacity: 1;
}

.ekko-lightbox .glyphicon-chevron-left {
    left: 0;
    float: left;
    padding-left: 15px;
    text-align: left;
}

.ekko-lightbox .glyphicon-chevron-right {
    right: 0;
    float: right;
    padding-right: 15px;
    text-align: right;
}

.ekko-lightbox .modal-footer {
    text-align: left;
}